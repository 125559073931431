<template>
  <div class="page home-page">
    <div class="container">
      <h1 class="hidden">Home Page</h1>
      <MainSlider v-if="isShowMainSlider" :items="slides"></MainSlider>
      <SiteFeatures
        v-if="isShowSiteFeatures"
        :features="features"
      ></SiteFeatures>
      <SeasonalSlider
        v-if="isShowSeasonalSlider"
        :items="seasonalSlides"
      ></SeasonalSlider>
      <Divider />
      <FeaturedCollection
        v-if="isShowFeaturedCollection"
        :is-measure-performance="true"
        :items="featuredCollection"
      ></FeaturedCollection>
      <client-only
        v-if="isShowBestSelling"
        :is-show="componentStore.homePage.isShowBestSelling"
      >
        <Divider />
        <BestSelling :collections="bestSelling"></BestSelling>
      </client-only>
      <client-only
        v-if="isShowTopics"
        :is-show="componentStore.homePage.isShowTopics"
      >
        <Divider />
        <Topics :items="topics"></Topics>
      </client-only>
      <client-only
        v-if="isShowReviews"
        :is-show="componentStore.homePage.isShowReviews"
      >
        <Divider />
        <CustomerReviews :reviews="reviews"></CustomerReviews>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onServerPrefetch, useSSRContext } from 'vue'

import MainSlider from './MainSlider.vue'
import SiteFeatures from './SiteFeatures.vue'
import SeasonalSlider from './SeasonalSlider.vue'
import FeaturedCollection from './FeaturedCollection.vue'
import BestSelling from './BestSelling.vue'
import Divider from '@/components/Divider.vue'
import useSettingStore from '@/store/setting'
import useComponentStore from '@/store/component'
import { runTaskOnIdleTime } from '@/utils/idlize'
import Topics from './Topics.vue'
import CustomerReviews from './CustomerReviews.vue'
import { isOnServer } from '@/utils/ssr'
import { appendToHead } from '@/composables/appendToHead'
import { SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS } from '@/utils/constants'
import { useSettings } from '@/composables/settings'

const { filterSettingsByUserSegment } = useSettings()
const settingStore = useSettingStore()
const componentStore = useComponentStore()
const slides = computed(() =>
  filterSettingsByUserSegment(settingStore.pages?.homePage?.mainSlider?.slides)
)
const features = computed(
  () => settingStore.pages?.homePage?.siteFeatures?.features
)
const seasonalSlides = computed(
  () => settingStore.pages?.homePage?.seasonalSlider?.slides
)
const featuredCollection = computed(
  () => settingStore.pages?.homePage?.featuredCollection?.items
)
const bestSelling = computed(() =>
  filterSettingsByUserSegment(
    settingStore.pages?.homePage?.bestSelling?.collections
  )
)
const topics = computed(() => settingStore.pages?.homePage?.topics?.groupTopics)
const reviews = computed(
  () => settingStore.pages?.homePage?.customerReviews?.items
)

const isShowMainSlider = computed(
  () => settingStore.pages?.homePage?.mainSlider?.isShow
)
const isShowSiteFeatures = computed(
  () => settingStore.pages?.homePage?.siteFeatures?.isShow
)
const isShowSeasonalSlider = computed(
  () => settingStore.pages?.homePage?.seasonalSlider?.isShow
)
const isShowFeaturedCollection = computed(
  () => settingStore.pages?.homePage?.featuredCollection?.isShow
)
const isShowBestSelling = computed(
  () => settingStore.pages?.homePage?.bestSelling?.isShow
)
const isShowTopics = computed(
  () => settingStore.pages?.homePage?.topics?.isShow
)
const isShowReviews = computed(
  () => settingStore.pages?.homePage?.customerReviews?.isShow
)

const ssrContext = isOnServer && useSSRContext()

onServerPrefetch(async () => {
  // inject meta tags into head tag
  if (ssrContext) {
    appendToHead(
      `<meta property="og:type" content="website" />`,
      SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS,
      ssrContext
    )
  }
})

onMounted(() => {
  runTaskOnIdleTime(() => {
    componentStore.homePage.isShowBestSelling = true
  })
  runTaskOnIdleTime(() => {
    componentStore.homePage.isShowTopics = true
  })
  runTaskOnIdleTime(() => {
    componentStore.homePage.isShowReviews = true
  })
})
</script>

<style lang="scss">
.home-page {
  .carousel {
    &__item {
      width: 100%;
    }
    .video.carousel__video {
      aspect-ratio: 1;
      @include media-md-up {
        aspect-ratio: 3.1199;
      }
    }
  }
}
</style>
