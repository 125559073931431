<template>
  <div class="topics">
    <h2 class="text--center mb-lg">Topics</h2>
    <Tab :default="0" v-if="deviceStore.isMobile">
      <div class="tab-header-container--flex mb-md">
        <TabHeader
          :index="index"
          :key="index"
          v-for="(tab, index) of items"
          v-slot="{ isActive }"
        >
          <sib-badge :role="isActive ? 'primary' : ''">
            {{ tab.title }}
          </sib-badge>
        </TabHeader>
      </div>
      <TabContent :index="index" :key="index" v-for="(item, index) of items">
        <ul>
          <li v-for="(topic, index) of item.topics" :key="index">
            <sib-link :to="topic.handle" :title="topic.title">
              {{ topic.title }}
            </sib-link>
          </li>
        </ul>
      </TabContent>
    </Tab>
    <div class="topics__groups" v-else>
      <div class="topic__group" v-for="(item, index) of items" :key="index">
        <h3>{{ item.title }}</h3>
        <ul>
          <li v-for="(topic, index) of item.topics" :key="index">
            <sib-link :to="topic.handle" :title="topic.title">
              {{ topic.title }}
            </sib-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TabHeader from '@/ui-kits/TabHeader.vue'
import TabContent from '@/ui-kits/TabContent.vue'
import Tab from '@/ui-kits/Tab.vue'
import useDeviceStore from '@/store/device'
import SibBadge from '@/ui-kits/Badge.vue'

interface Topic {
  handle: string
  title: string
}

interface TopicGroup {
  topics: Topic[]
  title: string
}

interface TopicProps {
  items: TopicGroup[]
}

withDefaults(defineProps<TopicProps>(), {
  items: () => [],
})
const deviceStore = useDeviceStore()
</script>

<style lang="scss">
.topics {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 1em;
      text-align: center;
      @include media-desktop {
        text-align: left;
      }
    }
  }
}

.topics__groups {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1em;
}
</style>
