<template>
  <div class="container-best-selling">
    <h2 class="text--center mb-lg">Best Selling</h2>
    <Tab :default="0" @tabChange="onTabChange">
      <div class="tab-header-container--flex mb-md">
        <TabHeader
          :index="index"
          :key="index"
          v-for="(tab, index) of collections"
          v-slot="{ isActive }"
        >
          <sib-badge :role="isActive ? 'primary' : ''" class="hidden-md-up">{{
            tab.title
          }}</sib-badge>
          <span class="hidden-md-down">
            {{ tab.title }}
            <span class="tab__header-divider">::</span>
          </span>
        </TabHeader>
      </div>
      <TabContent
        :index="index"
        :key="index"
        v-for="(collection, index) of collections"
      >
        <ProductGrid
          :lazyload-start-index="0"
          :products="getCurrentCollection(index).products"
          :isShowProductListOldVersion="true"
        />
      </TabContent>
    </Tab>
    <div class="text--center mt-md">
      <sib-link
        v-if="currentSelectedCollection"
        :to="buildCollectionUrl(currentSelectedCollection.handle)"
        :title="currentSelectedCollection.title"
        class="sib-button sib-button--primary"
      >
        See more {{ currentSelectedCollection.title }}
      </sib-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Collection } from '@/types'
import { onMounted, reactive } from 'vue'
import Tab from '@/ui-kits/Tab.vue'
import TabHeader from '@/ui-kits/TabHeader.vue'
import TabContent from '@/ui-kits/TabContent.vue'
import ProductGrid from '@/components/product/ProductGrid.vue'
import {
  buildCollectionUrl,
  isProductHasTags,
  isTMProduct,
} from '@/utils/product'
import {
  cloneDeep,
  QUERY_STRING_NO_CACHE,
  RESTRIC_VN_LOCATION_PRODUCT_TAGS,
  VN_COUNTRY_CODE,
} from '@/utils'
import { computed, ref } from '@vue/reactivity'
import useSettingStore from '@/store/setting'
import useCollectionStore from '@/store/collection'
import useUserStore from '@/store/user'
import SibBadge from '@/ui-kits/Badge.vue'
import { useRoute } from 'vue-router'
import useRequestLocationStore from '@/store/requestLocation'

const requestLocationStore = useRequestLocationStore()
const route = useRoute()

interface CollectionProp {
  handle: string
  title: string
}

interface BestSellingProps {
  collections: CollectionProp[]
}

const props = withDefaults(defineProps<BestSellingProps>(), {
  collections: () => [],
})

const settingStore = useSettingStore()
const collectionStore = useCollectionStore()
const userStore = useUserStore()
const numberOfProducts = 10
const placeholderCollection = {
  products: Array(numberOfProducts).fill(undefined),
} as Collection

const collectionsData = reactive<Collection[]>([])
const selectedTabIndex = ref(0)
const currentSelectedCollection = computed(() => {
  return props.collections[selectedTabIndex.value]
})

const isRequestVNLocation = computed(
  () => requestLocationStore.countryCode.toUpperCase() == VN_COUNTRY_CODE
)

function getCurrentCollection(index: number) {
  if (!collectionsData[index]) return placeholderCollection
  const collection = collectionsData[index]
  const clonedCollection = cloneDeep(collection)
  // filter out TM products and restricted products
  clonedCollection.products = clonedCollection.products?.filter((product) => {
    if (!product) return false
    if (isTMProduct(product, settingStore.tmProductTags)) return false
    if (
      userStore.userBlockedProductTags &&
      userStore.userBlockedProductTags.length &&
      isProductHasTags(product, userStore.userBlockedProductTags)
    )
      return false
    //filter out CLO1 or clo1 product for IP VN
    if (
      isRequestVNLocation.value &&
      isProductHasTags(product, RESTRIC_VN_LOCATION_PRODUCT_TAGS.split(','))
    ) {
      return false
    }
    return true
  })
  // limint number of products
  if (
    clonedCollection.products?.length &&
    clonedCollection.products?.length > numberOfProducts
  ) {
    clonedCollection.products = clonedCollection.products.slice(
      0,
      numberOfProducts
    )
  }
  return clonedCollection
}

const onTabChange = async (tabIndex: string | number) => {
  const index = +tabIndex
  selectedTabIndex.value = index
  if (collectionsData[index]) return
  const selectedCollection = props.collections[index]
  if (selectedCollection) {
    const collection = await collectionStore.getCollectionByHandle({
      handle: selectedCollection.handle,
      isForceLoadNewData: !!route.query[QUERY_STRING_NO_CACHE],
    })
    if (collection) {
      collectionsData[index] = collection
    }
  }
}
</script>

<style lang="scss">
.tab-header-container--flex {
  display: flex;
  gap: 1em;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-md-up {
    .tab-header-container {
      margin-bottom: var(--space-large);
      justify-content: center;
      gap: 0;
    }
  }
}

.container-best-selling {
  @include media-md-up {
    .tab-header-container--flex {
      margin-bottom: var(--space-large);
      justify-content: center;
      gap: 0;
    }

    .tab__header {
      font-weight: 700;
      cursor: pointer;

      &:last-child {
        .tab__header-divider {
          display: none;
        }
      }

      &-divider {
        margin: 0 0.75em;
        letter-spacing: 0.4em;
      }

      &--active {
        color: var(--color-primary);

        .tab__header-divider {
          color: var(--text-color-primary);
        }
      }
    }
  }
}
</style>
