<template>
  <div class="seasonal-carousel mt-md">
    <sib-carousel
      :items="transformedProps"
      :options="carouselOptions"
    ></sib-carousel>
  </div>
</template>

<script setup lang="ts">
import { CarouselOptions, Image } from '@/types'
import { computed } from '@vue/reactivity'
interface SlideItem {
  image: Image
  link?: string
}

const props = withDefaults(defineProps<{ items: Array<SlideItem> }>(), {
  items: () => [],
})

const carouselOptions: CarouselOptions = {
  Dots: false,
  slidesPerPage: 1,
}

const transformedProps = computed(() => {
  return props.items.map((item) => {
    item.image = Object.assign({}, item.image, {
      width: 3750,
      height: 1750,
      sources: [
        {
          size: 330,
        },
        {
          size: 420,
          media: 361,
        },
        {
          size: 740,
          media: 450,
        },
        {
          size: 300,
          media: 768,
        },
        {
          size: 360,
          media: 992,
        },
        {
          size: 500,
          media: 1400,
        },
      ],
    })
    return item
  })
})
</script>

<style lang="scss">
.seasonal-carousel .carousel__image {
  border: solid 1px var(--border-color);
}

@include media-md-up {
  .seasonal-carousel {
    --carousel-slide-width: calc((100% - 1px) / 3);

    .carousel__slide {
      padding: 0 0.5em;
    }
  }
}
</style>
